import { HomeComponent } from 'client/components/generic/Home';
import { Routes } from 'client/enums';
import {
  getServerSidePropsUserAndToken,
  withUser,
} from 'client/utils/auth-utils';
import { withAuthUserTokenSSR } from 'next-firebase-auth';
import { prisma } from 'prisma/client';
import React from 'react';

function Landing() {
  return <HomeComponent />;
}

export const getServerSideProps = withAuthUserTokenSSR()(async (context) => {
  const result = await getServerSidePropsUserAndToken(context, prisma, {
    redirectToWhenAuthed: Routes.REPORTS,
  });

  return result;
});

export default withUser(Landing);
